$fntA: "Rubik", sans-serif;
$fntABold: 600;

$fntB: "Merriweather", serif;
$fntBBold: 700;
$fntBBolder: 700;

/**
 * Reset
 */
$bodyClr: #1a1a1a;
$bodyBgClr: #f7f7f7;
$bodyLnkClr: #1a1a1a;

/**
 * Header
 */
$headerBgClr: #ffffff;
$headerBoxShw: 0 0 14px 0px rgba(52, 52, 52, 0.2);

$headerTxtLinkClr: #1a1a1a;
$headerTxtLinkHvrClr: #15a213;

$headerIcnClrA: #1a1a1a;
$headerIcnHvrClrA: #15a213;

/**
 * Drawer
 */
$drawerMenuBtnClr: #1a1a1a;
$drawerMenuBtnHvrClr: #15a213;

$drawerModalBgClr: #f7f7f7;

$drawerHeaderBgClr: #f7f7f7;

$drawerDivBgClr: darken(#f7f7f7, 14%);

$drawerBtnClrA: #ffffff;
$drawerBtnBgClrA: #15a213;
$drawerBtnBgHvrClrA: lighten($drawerBtnBgClrA, 4%);

$drawerBtnClrB: #1a1a1a;
$drawerBtnHvrClrB: #ffffff;
$drawerBtnBgClrB: transparent;
$drawerBtnBgHvrClrB: lighten(#15a213, 4%);
$drawerBtnBdrClrB: #15a213;
$drawerBtnBdrHvrClrB: lighten($drawerBtnBdrClrB, 4%);

$drawerCloseBtnClr: #1a1a1a;
$drawerCloseBtnHvrClr: #15a213;

/**
 * Intro
 */
$introBgClr: #f7f7f7;

$introHeadClr: #1a1a1a;
$introHeadDecClr: #15a213;

$introSubHeadClr: darken(#15a213, 4%);

$introSummaryParaClr: #1a1a1a;

$introTxtLinkClr: #1a1a1a;
$introTxtLinkHvrClr: #15a213;

$introBtnClrA: #ffffff;
$introBtnBgClrA: #15a213;
$introBtnBgHvrClrA: lighten($introBtnBgClrA, 4%);

$introBtnClrB: #1a1a1a;
$introBtnHvrClrB: #ffffff;
$introBtnBgClrB: transparent;
$introBtnBgHvrClrB: lighten(#15a213, 4%);
$introBtnBdrClrB: #15a213;
$introBtnBdrHvrClrB: lighten($introBtnBdrClrB, 4%);

/**
 * Benefits Marquee
 */
$benefitsMarqueeBgClr: #ffffff;

$benefitsMarqueeItemClr: #919191;
$benefitsMarqueeItemHvrClr: #1a1a1a;

/**
 * Features
 */
$featuresBgClr: #f7f7f7;

$featuresHeadClr: #15a213;
$featuresHeadDecClr: #1a1a1a;

$featuresSubHeadClr: #1a1a1a;

$featuresCatsListBgClr: #ffffff;
$featuresCatsListBoxShw: 0 0 14px 0px rgba(52, 52, 52, 0.2);

$featuresCatHeadClr: #15a213;

$featuresCatIcnClr: #15a213;

$featuresCatSummaryClr: #1a1a1a;

$featuresCatSummaryLnkHvrClr: #15a213;

/**
 * Guide
 */
$guideBgClr: #f7f7f7;

$guideHeadClr: #15a213;
$guideHeadDecClr: #1a1a1a;

$guideSubHeadClr: #1a1a1a;

$guideStepsListBgClr: #ffffff;
$guideStepsListBoxShw: 0 0 14px 0px rgba(52, 52, 52, 0.2);

$guideStepHeadClr: #15a213;

$guideStepCounterClr: #ffffff;
$guideStepCounterbgClr: #15a213;

$guideStepCatBdrClr: #e6e6e6;

$guideStepIntroClr: #1a1a1a;

$guideStepCatHeadClr: #15a213;

$guideStepCatSummaryClr: #1a1a1a;

$guideStepOutroClr: #1a1a1a;

$guideStepBtnClrA: #ffffff;
$guideStepBtnBgClrA: #15a213;
$guideStepBtnBgHvrClrA: lighten($guideStepBtnBgClrA, 4%);

/**
 * Footer
 */
$footerBgClr: #ffffff;
$footerBoxShw: 0 0 14px 0px rgba(52, 52, 52, 0.2);

$footerBtnClrA: #ffffff;
$footerBtnBgClrA: #15a213;
$footerBtnBgHvrClrA: lighten($footerBtnBgClrA, 4%);

$footerBtnClrB: #1a1a1a;
$footerBtnHvrClrB: #ffffff;
$footerBtnBgClrB: transparent;
$footerBtnBgHvrClrB: lighten(#15a213, 4%);
$footerBtnBdrClrB: #15a213;
$footerBtnBdrHvrClrB: lighten($footerBtnBdrClrB, 4%);

$footerIcnClrA: #1a1a1a;
$footerIcnHvrClrA: #15a213;

$footerCopyBgClr: #f7f7f7;
$footerCopyClr: #111111;
