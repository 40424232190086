@import "../../styles/_sets.scss";

.cnt {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.25s ease-out;
  background-color: $headerBgClr;
  box-shadow: $headerBoxShw;
}

@media (min-width: 1100px) {
  .cnt {
    background-color: transparent;
    box-shadow: none;
  }

  .cntTrans {
    background-color: $headerBgClr;
    box-shadow: $headerBoxShw;
  }
}

.cntIn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.375rem 0.625rem;
  width: 100%;
  transition: padding 0.2s ease-out;
}

@media (min-width: 620px) {
  .cntIn {
    padding: 0.5rem 1.5rem 0.5rem 0.625rem;
  }
}

@media (min-width: 900px) {
  .cntIn {
    padding: 1.5rem 1.5rem 1.5rem 0.625rem;
    max-width: 96rem;
    margin: 0 auto;
  }

  .cntInTrans {
    padding: 0.75rem 1.5rem 0.75rem 0.625rem;
  }
}

@media (min-width: 1100px) {
  .cntIn {
    padding: 1.5rem 3rem;
  }

  .cntInTrans {
    padding: 0.75rem 3rem;
  }
}

.cntDrawer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cntLogo {
  display: block;
  width: 100%;
  max-width: 11rem;
  cursor: pointer;
  margin: 0 0 0 0.75rem;
  transition: all 0.25s ease-in-out;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media (min-width: 900px) {
  .cntLogo {
    max-width: 17.5rem;
  }
}

@media (min-width: 1100px) {
  .cntLogo {
    margin: 0;
  }
}

.cntMenu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cntTxtLinks {
  display: none;
}

@media (min-width: 1100px) {
  .cntTxtLinks {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.txtLink {
  color: $headerTxtLinkClr;
  font-size: 1.375rem;
  font-weight: $fntABold;
  white-space: nowrap;
  cursor: pointer;
  display: block;
  padding: 0.125rem 1.5rem 0.125rem 1.5rem;
  transition: all .2s ease-in-out;

  &:hover {
    color: $headerTxtLinkHvrClr;
  }
}

.cntIconLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;

  li {
    padding-left: 1rem;

    &:first-child {
      padding-left: 0;
    }
  }
}

.icnLink {
  color: $headerIcnClrA;
  display: block;
  transition: all .2s ease-in-out;

  &:hover {
    color: $headerIcnHvrClrA;
  }

  svg {
    display: block;
    width: auto;
    height: 1.625rem;
  }
}

@media (min-width: 900px) {
  .icnLink {
    svg {
      height: 1.875rem;
    }
  }
}
