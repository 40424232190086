.btn {
  composes: btnC from "../../styles/_btns.scss";
  padding: 0.5rem;
  transition: all 0.25s ease-out;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}
