@import "../../../styles/_sets.scss";

.cnt {
  padding: 3.125rem 0 3.0625rem 0;
  background-color: $benefitsMarqueeBgClr;
}

.item {
  color: $benefitsMarqueeItemClr;
  font-family: $fntB;
  font-size: 1.25rem;
  text-transform: uppercase;
  padding: 0 2rem;
  position: relative;
  transition: all 0.25s ease-in-out;
  cursor: default;

  &:hover {
    color: $benefitsMarqueeItemHvrClr;
  }

  &:before {
    color: $benefitsMarqueeItemClr;
    content: "\2022";
    position: absolute;
    right: -0.5rem;
  }
}

@media (min-width: 760px) {
  .item {
    font-size: 2rem;
  }
}