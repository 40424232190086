@import "../../styles/_sets.scss";

.cont {
  position: relative;
}

@media (min-width: 1370px) {
  .cont_in {
    position: relative;
    z-index: 5;
  }
}
