@import "../../../styles/_sets.scss";

.cnt {
  background-color: $introBgClr;
}

@media (min-width: 1370px) {
  .cnt {
    position: fixed;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
  }
}

.cntInA {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

@media (min-width: 1370px) {
  .cntInA {
    width: 100%;
    height: 100vh;
  }
}

.cntVideo {
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cntImgBg {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.cntInB {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  width: 100%;
  padding: 7rem 1.5rem 7rem 1.5rem;
}

@media (min-width: 900px) {
  .cntInB {
    padding: 8rem 1.5rem 7rem 1.5rem;
  }
}

@media (min-width: 1370px) {
  .cntInB {
    justify-content: space-between;
    flex-direction: row;
    max-width: 90rem;
    padding: 4rem 3rem 4rem 3rem;
  }
}

.colA {
  width: 100%;
  margin-top: 3rem;
}

@media (min-width: 1370px) {
  .colA {
    width: 66%;
  }
}

@media (min-width: 1610px) {
  .colA {
    width: 55%;
    margin-top: 0;
  }
}

.cntHeading {
  color: $introHeadClr;
  font-family: $fntB;
  font-size: 2rem;
  font-weight: $fntBBolder;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 1.5rem;

  span {
    color: $introHeadDecClr;
  }
}

@media (min-width: 400px) {
  .cntHeading {
    font-size: 3rem;
  }
}

@media (min-width: 800px) {
  .cntHeading {
    font-size: 3.5rem;
  }
}

@media (min-width: 1370px) {
  .cntHeading {
    font-size: 4rem;
    text-align: left;
  }
}

.cntCopy {
  p {
    color: $introSummaryParaClr;
    font-size: 1.125rem;
    text-align: center;
    padding-top: 1.625rem;

    &:first-child {
      padding-top: 0;
    }
  }

  em {
    font-style: italic;
  }

  a {
    color: $introTxtLinkClr;
    text-decoration: underline;
    transition: all .2s ease-in-out;

    &:hover {
      color: $introTxtLinkHvrClr;
    }
  }
}

@media (min-width: 500px) {
  .cntCopy {
    p {
      font-size: 1.25rem;
    }
  }
}

@media (min-width: 800px) {
  .cntCopy {
    p {
      font-size: 1.5rem;
      max-width: 47rem;
      margin: 0 auto;
    }
  }
}

@media (min-width: 1370px) {
  .cntCopy {
    p {
      text-align: left;
      max-width: none;
      margin: 0;
    }
  }
}

.cntBtnLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 40rem;
  margin: 2.825rem auto 0 auto;

  li {
    width: 45%;
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

@media (min-width: 1370px) {
  .cntBtnLinks {
    justify-content: flex-start;
    margin: 2.825rem 0 0 0;
  }
}

.btnLinkA {
  composes: btnA from "../../../styles/_btns.scss";
  color: $introBtnClrA;
  padding: 0.375rem 1rem;
  background-color: $introBtnBgClrA;
  cursor: pointer;

  &:hover {
    background-color: $introBtnBgHvrClrA;
  }
}

@media (min-width: 500px) {
  .btnLinkA {
    font-size: 1.625rem;
    padding: 1rem 1.125rem;
  }
}

.btnLinkB {
  composes: btnB from "../../../styles/_btns.scss";
  color: $introBtnClrB;
  padding: 0.25rem 0.875rem;
  background-color: $introBtnBgClrB;
  border-color: $introBtnBdrClrB;
  cursor: pointer;

  &:hover {
    color: $introBtnHvrClrB;
    background-color: $introBtnBgHvrClrB;
    border-color: $introBtnBdrHvrClrB;
  }
}

@media (min-width: 500px) {
  .btnLinkB {
    font-size: 1.625rem;
    padding: 0.875rem 1rem;
  }
}

.colB {
  width: 100%;

  img {
    display: block;
    width: 100%;
    max-width: 31.5rem;
    height: auto;
    margin: 0 auto;
  }
}

@media (min-width: 1370px) {
  .colB {
    width: 29%;
    margin-left: 4rem;
  }
}

@media (min-width: 1610px) {
  .colB {
    width: 40%;
    margin-left: 4rem;
  }
}

.spacer {
  display: none;
}

@media (min-width: 1370px) {
  .spacer {
    display: block;
    position: relative;
    z-index: -1;
    height: 100vh;
  }
}
