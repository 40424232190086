@import "../../../styles/_sets.scss";

.cnt {
  padding: 7rem 0 9rem 0;
  background-color: $guideBgClr;
}

.cntInA {
  width: 100%;
  padding: 0 1.5rem 0 1.5rem;
}

@media (min-width: 1370px) {
  .cntInA {
    max-width: 82rem;
    padding: 0 3rem 0 3rem;
    margin: 0 auto;
  }
}

.cntHeading {
  color: $guideHeadClr;
  font-family: $fntB;
  font-size: 2.25rem;
  font-weight: $fntBBolder;
  line-height: 1.4;
  text-align: center;

  span {
    color: $guideHeadDecClr;
  }
}

@media (min-width: 400px) {
  .cntHeading {
    font-size: 3rem;
  }
}

@media (min-width: 800px) {
  .cntHeading {
    font-size: 3.5rem;
  }
}

@media (min-width: 1370px) {
  .cntHeading {
    font-size: 4.5rem;
  }
}

.cntSubHeading {
  color: $guideSubHeadClr;
  font-family: $fntB;
  font-size: 1.5rem;
  font-weight: $fntBBold;
  line-height: 1.4;
  text-align: center;
  margin: 0.75rem 0 0 0;
}

@media (min-width: 400px) {
  .cntSubHeading {
    font-size: 2rem;
  }
}

@media (min-width: 800px) {
  .cntSubHeading {
    font-size: 2.25rem;
  }
}

@media (min-width: 1370px) {
  .cntSubHeading {
    font-size: 2.5rem;
  }
}

.cntInB {
  width: 100%;
  padding: 0 1.5rem;
  margin: 5rem auto 0 auto;
}

@media (min-width: 1370px) {
  .cntInB {
    max-width: 110rem;
  }
}

.cntStepList {
  width: 100%;
  max-width: 64rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  counter-reset: guideStep;
  margin: 0 auto;
}

.cntStepListItem {
  width: 100%;
  position: relative;
  background-color: $guideStepsListBgClr;
  box-shadow: $guideStepsListBoxShw;
  border-radius: 1rem;
  margin: 2.25rem 0 0 0;

  &:first-child {
    margin: 0;
  }

  &:before {
    color: $guideStepCounterClr;
    font-size: 30px;
    line-height: 58px;
    text-align: center;
    position: absolute;
    z-index: 100;
    width: 3.625rem;
    height: 3.625rem;
    top: -1rem;
    left: -1rem;
    border-radius: 50%;
    counter-increment: guideStep;
    content: counter(guideStep);
    background-color: $guideStepCounterbgClr;
    -webkit-backface-visibility: hidden;
  }
}

@media (min-width: 735px) {
  .cntStepListItem {
    margin: 3rem 0 0 0;

    &:first-child {
      margin: 0;
    }
  }
}

.stepHeading {
  color: $guideStepHeadClr;
  font-family: $fntA;
  font-size: 1.5rem;
  line-height: 1.4;
  text-align: center;
  padding: 2.75rem 1.5rem 0 1.5rem;
}

@media (min-width: 400px) {
  .stepHeading {
    font-size: 2rem;
  }
}

@media (min-width: 800px) {
  .stepHeading {
    font-size: 2.5rem;
  }
}

.stepIntro {
  color: $guideStepIntroClr;
  font-size: 1.125rem;
  text-align: center;
  width: 100%;
  max-width: 50rem;
  padding: 1.625rem 1.5rem 0 1.5rem;
  margin: 0 auto;
}

@media (min-width: 500px) {
  .stepIntro {
    font-size: 1.25rem;
  }
}

@media (min-width: 800px) {
  .stepIntro {
    font-size: 1.5rem;
  }
}

.cntStepCatListBase {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 2.75rem 0 0 0;

  li {
    width: 100%;
    border-top: 1px solid $guideStepCatBdrClr;

    &:last-child {
      border-bottom: 1px solid $guideStepCatBdrClr;
    }
  }
}

@media (min-width: 500px) {
  .cntStepCatListBase {
    padding: 0 2.25rem;

    li {
      border: 1px solid $guideStepCatBdrClr;
    }
  }

  .cntStepCatListA {
    li {
      border-top: none;
  
      &:first-child {
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;
        border-top: 1px solid $guideStepCatBdrClr;
      }
  
      &:last-child {
        border-bottom-left-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
      }
    }
  }

  .cntStepCatListB {
    li {
      border-radius: 0.75rem;
    }
  }
}

@media (min-width: 900px) {
  .cntStepCatListBase {
    align-items: initial;
    flex-direction: row;
  }

  .cntStepCatListA {
    li {
      max-width: 19.25rem;
      flex: 1;
      border-left: none;
      border-top: 1px solid $guideStepCatBdrClr;

      &:first-child {
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0.75rem;
        border-left: 1px solid $guideStepCatBdrClr;
      }
  
      &:last-child {
        border-top-right-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        border-bottom-left-radius: 0;
      }
    }
  }

  .cntStepCatListB {
    li {
      max-width: 40rem;
    }
  }
}

.stepCatHeading {
  color: $guideStepCatHeadClr;
  font-family: $fntB;
  font-weight: $fntBBold;
  font-size: 1.5rem;
  text-align: center;
  padding: 2rem 1.5rem 1.25rem 1.5rem;
}

@media (min-width: 800px) {
  .stepCatHeading {
    font-size: 1.75rem;
  }
}

.stepCatSummary {
  color: $guideStepCatSummaryClr;
  font-size: 1.125rem;
  text-align: center;
  padding: 0 1.5rem 2rem 1.5rem;

  em {
    font-style: italic;
  }
}

@media (min-width: 500px) {
  .stepCatSummary {
    font-size: 1.25rem;
    text-align: left;
  }
}

.stepOutro {
  color: $guideStepOutroClr;
  font-size: 1.125rem;
  text-align: center;
  width: 100%;
  max-width: 50rem;
  padding: 2.75rem 1.5rem;
  margin: 0 auto;

  em {
    font-style: italic;
  }
}

@media (min-width: 500px) {
  .stepOutro {
    font-size: 1.25rem;
  }
}

.cntStepBtn {
  width: 100%;
  max-width: 21rem;
  padding: 0 1.5rem;
  margin: 2.75rem auto 2.75rem auto;
}

.stepBtnLink {
  composes: btnA from "../../../styles/_btns.scss";
  color: $guideStepBtnClrA;
  padding: 0.375rem 1rem;
  background-color: $guideStepBtnBgClrA;

  &:hover {
    background-color: $guideStepBtnBgHvrClrA;
  }
}

@media (min-width: 500px) {
  .stepBtnLink {
    font-size: 1.625rem;
    padding: 1rem 1.125rem;
  }
}