@import "../../../styles/_sets.scss";

.cnt {
  padding: 7rem 0 0 0;
  background-color: $featuresBgClr;
}

.cntInA {
  width: 100%;
  padding: 0 1.5rem 0 1.5rem;
}

@media (min-width: 1370px) {
  .cntInA {
    max-width: 82rem;
    padding: 0 3rem 0 3rem;
    margin: 0 auto;
  }
}

.cntHeading {
  color: $featuresHeadClr;
  font-family: $fntB;
  font-size: 2.75rem;
  font-weight: $fntBBolder;
  line-height: 1.4;
  text-align: center;

  span {
    color: $featuresHeadDecClr;
  }
}

@media (min-width: 400px) {
  .cntHeading {
    font-size: 3rem;
  }
}

@media (min-width: 800px) {
  .cntHeading {
    font-size: 3.5rem;
  }
}

@media (min-width: 1370px) {
  .cntHeading {
    font-size: 4.5rem;
  }
}

.cntSubHeading {
  color: $featuresSubHeadClr;
  font-family: $fntB;
  font-size: 1.5rem;
  font-weight: $fntBBold;
  line-height: 1.4;
  text-align: center;
  margin: 0.75rem 0 0 0;
}

@media (min-width: 400px) {
  .cntSubHeading {
    font-size: 2rem;
  }
}

@media (min-width: 800px) {
  .cntSubHeading {
    font-size: 2.25rem;
  }
}

@media (min-width: 1370px) {
  .cntSubHeading {
    font-size: 2.5rem;
  }
}

.cntInB {
  width: 100%;
  padding: 0 1.5rem;
  margin: 5rem auto 0 auto;

  .cntCatList {
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }
}

@media (min-width: 800px) {
  .cntInB {
    .cntCatList {
      margin-top: 3rem;
  
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

@media (min-width: 1370px) {
  .cntInB {
    max-width: 110rem;
  }
}

.cntCatList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  li {
    max-width: 40rem;
    background-color: $featuresCatsListBgClr;
    box-shadow: $featuresCatsListBoxShw;
    border-radius: 1rem;
    position: relative;
    margin: 1.5rem 0 0 0;

    &:first-child {
      margin: 0;
    }
  }
}

@media (min-width: 736px) {
  .cntCatList {
    li {
      margin: 3rem 0 0 0;

      &:first-child {
        margin: 0;
      }
    }
  }
}

@media (min-width: 800px) {
  .cntCatList {
    li {
      margin: 3rem 1.5rem 0 1.5rem;

      &:first-child {
        margin: 0 1.5rem;
      }
    }
  }
}

@media (min-width: 1370px) {
  .cntCatList {
    align-items: initial;
    flex-direction: row;

    li {
      flex: 1;
      max-width: auto;
      margin: 0 1.5rem;
    }
  }
}

.catIcon {
  color: $featuresCatIcnClr;
  margin: 4rem 0 1.5rem 0;

  svg {
    display: block;
    width: auto;
    height: 9rem;
    margin: 0 auto;
  }
}

.catHeading {
  color: $featuresCatHeadClr;
  font-family: $fntB;
  font-size: 1.5rem;
  font-weight: $fntBBold;
  line-height: 1.4;
  text-align: center;
  padding: 0 1.5rem;
  margin-bottom: 1.625rem;
}

@media (min-width: 400px) {
  .catHeading {
    font-size: 2rem;
  }
}

@media (min-width: 800px) {
  .catHeading {
    font-size: 2.25rem;
    padding: 0 2.5rem;
  }
}

@media (min-width: 1500px) {
  .catHeading {
    font-size: 2.75rem;
  }
}

.cntCatSummary {
  padding-bottom: 2.5rem;

  p {
    color: $featuresCatSummaryClr;
    font-size: 1.125rem;
    text-align: center;
    padding: 1.625rem 1.5rem 0 1.5rem;

    &:first-child {
      padding-top: 0;
    }
  }

  em {
    font-style: italic;
  }

  a {
    text-decoration: underline;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $featuresCatSummaryLnkHvrClr;
    }
  }
}

@media (min-width: 500px) {
  .cntCatSummary {
    p {
      font-size: 1.25rem;
    }
  }
}

@media (min-width: 800px) {
  .cntCatSummary {
    p {
      font-size: 1.5rem;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }
}

@media (min-width: 1370px) {
  .cntCatSummary {
    p {
      text-align: left;
    }
  }
}
