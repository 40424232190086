@import "../../styles/_sets.scss";

.cnt {
  background-color: $footerBgClr;
  box-shadow: $footerBoxShw;
}

.cntInA {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 96rem;
  padding: 3.5rem 1rem;
  margin: 0 auto;
}

@media (min-width: 620px) {
  .cntInA {
    padding: 3.5rem 1.5rem;
  }
}

@media (min-width: 900px) {
  .cntInA {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 1100px) {
  .cntInA {
    max-width: 96rem;
    padding: 3.5rem 3rem;
  }
}

.cntLogo {
  display: block;
  width: 100%;
  max-width: 14rem;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media (min-width: 900px) {
  .cntLogo {
    max-width: 17.5rem;
    margin-right: 1.5rem;
  }
}

.cntBtnLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;

  li {
    padding-left: 1rem;

    &:first-child {
      padding-left: 0;
    }
  }
}

@media (min-width: 900px) {
  .cntBtnLinks {
    margin: 0;
  }
}

.btnLinkA {
  composes: btnA from "../../styles/_btns.scss";
  color: $footerBtnClrA;
  padding: 0.375rem 1rem;
  background-color: $footerBtnBgClrA;
  cursor: pointer;

  &:hover {
    background-color: $footerBtnBgHvrClrA;
  }
}

@media (min-width: 900px) {
  .btnLinkA {
    padding: 0.5rem 1.125rem;
  }
}

.btnLinkB {
  composes: btnB from "../../styles/_btns.scss";
  color: $footerBtnClrB;
  padding: 0.25rem 0.875rem;
  background-color: $footerBtnBgClrB;
  border-color: $footerBtnBdrClrB;
  cursor: pointer;

  &:hover {
    color: $footerBtnHvrClrB;
    background-color: $footerBtnBgHvrClrB;
    border-color: $footerBtnBdrHvrClrB;
  }
}

@media (min-width: 900px) {
  .btnLinkB {
    padding: 0.375rem 1rem;
  }
}

.cntIconLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  li {
    padding-left: 1rem;

    &:first-child {
      padding-left: 0;
    }
  }
}

@media (min-width: 900px) {
  .cntIconLinks {
    margin: 0 0 0 1.5rem;
  }
}

.icnLink {
  color: $footerIcnClrA;
  display: block;
  transition: all .2s ease-in-out;

  &:hover {
    color: $footerIcnHvrClrA;
  }

  svg {
    display: block;
    width: auto;
    height: 1.625rem;
  }
}

@media (min-width: 900px) {
  .icnLink {
    svg {
      height: 1.875rem;
    }
  }
}

.cntCopy {
  background-color: $footerCopyBgClr;
}

.cntCopyIn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 96rem;
  padding: 3.5rem 1.5rem;
  margin: 0 auto;

  p {
    color: $footerCopyClr;
    font-size: 1rem;
    text-align: center;
    margin-top: 1.625rem;

    &:first-child {
      margin-top: 0;
    }
  }
}

@media (min-width: 800px) {
  .cntCopyIn {
    p {
      font-size: 1.125rem;
    }
  }
}

@media (min-width: 1100px) {
  .cntCopyIn {
    padding: 3rem 3rem;
  }
}