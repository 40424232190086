@import "./_sets.scss";

.btnA {
  font-family: $fntA;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  display: block;
  padding: 0.5rem 1.25rem;
  border-radius: 0.3125rem;
  transition: all .2s ease-in-out;

  &:hover {
    box-shadow: 0 0px 12px rgba(71, 71, 71, 0.4);
  }
}

.btnB {
  font-family: $fntA;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  display: block;
  padding: 0.375rem 1rem;
  border-radius: 0.3125rem;
  border: 2px solid transparent;
  transition: all .2s ease-in-out;

  &:hover {
    box-shadow: 0 0px 12px rgba(71, 71, 71, 0.4);
  }
}

.btnC {
  font: inherit;
  line-height: normal;
  color: inherit;
  cursor: pointer;
  display: block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  overflow: visible;
  background: transparent;
  appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:disabled {
    cursor: default;
  }
}
