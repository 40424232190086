@import "../../../styles/_sets.scss";

.cnt {
  display: block;
}

@media (min-width: 1100px) {
  .cnt {
    display: none;
  }
}

.menuBtn {
  composes: btnC from "../../../styles/_btns.scss";
  color: $drawerMenuBtnClr;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.625rem;
  box-sizing: content-box;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: $drawerMenuBtnHvrClr;
  }

  svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.modal :global(.MuiDrawer-paper) {
  background-color: $drawerModalBgClr;
}

.header {
  position: relative;
  min-height: 9.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $drawerHeaderBgClr;

  button {
    color: $drawerCloseBtnClr;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 2.125rem;
    height: 2.125rem;

    &:hover {
      color: $drawerCloseBtnHvrClr;
    }
  }
}

.logo {
  display: block;
  width: auto;
  height: 6.5rem;
}

.cntList {
  width: 250px;
}

.cntList :global(.MuiDivider-root) {
  background: $drawerDivBgClr;
}

.list {
  padding: 1rem 0;
}

.txtLink {
  color: $headerTxtLinkClr;
  font-size: 1.125rem;
  font-weight: $fntABold;
  white-space: nowrap;
  cursor: pointer;
  display: block;
  padding: 0.625rem 2rem;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: $headerTxtLinkHvrClr;
  }
}

.cntBtnLinks {
  margin: 2.125rem 2rem;

  li {
    padding-top: 1rem;

    &:first-child {
      padding-top: 0;
    }
  }
}

.btnLinkA {
  composes: btnA from "../../../styles/_btns.scss";
  color: $drawerBtnClrA;
  padding: 0.375rem 1rem;
  background-color: $drawerBtnBgClrA;
  cursor: pointer;

  &:hover {
    background-color: $drawerBtnBgHvrClrA;
  }
}

.btnLinkB {
  composes: btnB from "../../../styles/_btns.scss";
  color: $drawerBtnClrB;
  padding: 0.25rem 0.875rem;
  background-color: $drawerBtnBgClrB;
  border-color: $drawerBtnBdrClrB;
  cursor: pointer;

  &:hover {
    color: $drawerBtnHvrClrB;
    background-color: $drawerBtnBgHvrClrB;
    border-color: $drawerBtnBdrHvrClrB;
  }
}
